<template>
  <b-modal
    v-model="showModal"
    ref="ModalSelectBranch"
    id="modal-select-branch"
    centered
    @hidden="hide"
  >
    <template #modal-header>Branch List</template>
    <b-form-group>
      <template #label>
        <b-form-checkbox
          v-model="selectAllBranch"
          @change="toggleAllBranch()"
          :disabled="
            valid == 1
              ? allBranchCheck == allSelectBranchCheck
                ? true
                : false
              : false
          "
          class="my-2"
        >
          All Branches
        </b-form-checkbox>
      </template>
      <b-tabs content-class="mt-3" v-if="!isCounting" ref="b_tabs" fill>
        <b-tab v-for="(item, index) in list" :key="index">
          <template v-slot:title>
            <div>
              <span>{{ item.name }}</span>
              <span :id="`branch-${index}-count`">
                ({{ item.selected_count || 0 }})
              </span>
            </div>
          </template>
          <b-form-group>
            <template>
              <b-form-checkbox
                :ref="`all_${item.name}`"
                v-model="allSelected"
                :key="item.name"
                :value="item.name"
                @change="toggleAll(item.name, index)"
                :tabindex="index"
                :disabled="
                  valid == 1
                    ? item.count == item.branch_true_count
                      ? true
                      : false
                    : false
                "
              >
                {{ item.name }} All Branches
              </b-form-checkbox>
            </template>
            <b-form-checkbox-group v-model="selected" class="mt-3">
              <b-row>
                <b-col cols="6" v-for="(data, i) in item.branchList" :key="i">
                  <b-form-checkbox
                    class="mb-2"
                    :value="data.id"
                    :key="data.id"
                    @change="onChange(data.id)"
                    :id="'branch-' + data.plant_id"
                  >
                    {{ data.plant_id }} {{ data.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-checkbox-group>
          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-form-group>
    <template #modal-footer>
      <div class="content-between">
        <b-button class="btn border-btn" @click="hide"> Cancel </b-button>
        <b-button class="submit-btn" @click="acceptSelectBranch">
          Select
          <span id="branch-select-length">{{ selected.length }}</span> Branch
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    list: {
      required: true,
      type: Array,
    },
    id: {
      required: true,
      type: String,
    },
    valid: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      showModal: false,
      selected: [],
      allSelected: [],
      selectAllBranch: false,
      allBranch: [],
      branch: "",
      countBranch: [],
      allBranchCheck: 0,
      allSelectBranchCheck: 0,
      branchOld: [],
      lockSelected: [],
      isCounting: false,
      branchDelete: [],
      deleteBranch: [],
      tabIndex: 0,
      tabSelected: 0,
    };
  },

  mounted() {},
  methods: {
    show() {
      this.showModal = true;
      this.selected = [];
      this.allSelected = [];
      for (const participating of this.list) {
        let branchSelectedCount = 0;
        if (participating.selected_count == participating.branchList.length) {
          this.allSelected.push(participating.name);
        }
        for (const branch of participating.branchList) {
          if (branch.is_check) {
            this.selected.push(branch.id);
            this.lockSelected.push(branch.id);
            branchSelectedCount = branchSelectedCount + 1;
          }
        }
      }
      for (let i in this.list) {
        for (let j in this.list[i].branchList) {
          this.allBranchCheck =
            this.allBranchCheck + this.list[i].branch_true_count;
          this.allSelectBranchCheck =
            this.allSelectBranchCheck + this.list[i].count;
          if (this.list[i].branchList[j].is_check == true) {
            this.branchDelete.push(this.list[i].branchList[j].id);
          }
          // break;
        }
      }
      this.selectAllBranch = this.allSelected.length == this.list.length;
    },
    hide() {
      this.showModal = false;
    },
    toggleAll(branchGroup) {
      const ref = this.$refs[`all_${branchGroup}`][0];
      const selectBranch = this.list.find((el) => el.name == branchGroup);
      const id = selectBranch.branchList.map((el) => el.id);
      if (ref.isChecked) {
        selectBranch.selected_count = selectBranch.branchList.length;
        this.selected.push(...id);
      } else {
        selectBranch.selected_count = 0;
        const remove = this.selected.filter((el) => !id.includes(el));
        this.selected = remove;
      }
    },
    toggleAllBranch() {
      this.selected = [];
      this.allSelected = [];
      this.isCounting = true;

      this.allSelected = [];
      if (this.selectAllBranch) {
        for (const participating of this.list) {
          this.allSelected.push(participating.name);
          for (const branch of participating.branchList) {
            this.selected.push(branch.id);
          }
        }
      }

      this.isSelectAll();
    },
    isSelectAll() {
      let isCheckAllBranchCount = 0;

      for (const participating of this.list) {
        let branchSelectedCount = 0;
        for (const branch of participating.branchList) {
          if (!participating.selected_count) participating.selected_count = 0;
          if (this.selected.includes(branch.id)) {
            branchSelectedCount = branchSelectedCount + 1;
          }
        }
        participating.selected_count = branchSelectedCount;
        const countBranchEl = this.$refs[`span_${participating.name}`];
        if (countBranchEl) {
          countBranchEl[0].innerHTML = participating.selected_count;
        }
        if (branchSelectedCount == participating.branchList.length) {
          if (!this.allSelected.includes(participating.name)) {
            this.allSelected.push(participating.name);
          }
          isCheckAllBranchCount = isCheckAllBranchCount + 1;
        } else if (this.allSelected.includes(participating.name)) {
          this.allSelected.splice(
            this.allSelected.indexOf(participating.name),
            1
          );
        }
      }
      if (isCheckAllBranchCount == this.list.length && this.list.length !== 0) {
        this.selectAllBranch = true;
      } else this.selectAllBranch = false;
      this.isCounting = false;
    },
    onChange() {
      this.isCounting = true;
      for (const branchToDeleteId of this.branchDelete) {
        if (
          !this.selected.includes(branchToDeleteId) &&
          !this.deleteBranch.includes(branchToDeleteId)
        ) {
          this.deleteBranch.push(branchToDeleteId);
        } else if (
          this.selected.includes(branchToDeleteId) &&
          this.deleteBranch.includes(branchToDeleteId)
        ) {
          this.deleteBranch.splice(
            this.deleteBranch.indexOf(branchToDeleteId),
            1
          );
        }
      }

      this.isSelectAll();
    },
    acceptSelectBranch() {
      this.$emit("selectBranch", this.selected);

      this.showModal = false;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
  font-size: 18px;
}
.ft-weight {
  font-weight: 600;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}

::v-deep a {
  color: #707070 !important;
}
::v-deep .modal-dialog {
  @media (min-width: 576px) {
    max-width: 800px;
  }
}
::v-deep .modal-footer {
  display: block;
}
.btn-add-branch {
  background-color: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
  max-width: fit-content;
}
.pointer {
  cursor: pointer;
}
// .branch-name {
//   display: flex;
//   height: 30px;
//   justify-content: center;
//   align-items: center;
// }
</style>
